<template>
  <v-container class="table-filters" fluid>
    <v-row>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersStatus"
          :items="availableAccountStatusFilters"
          :label="$trans('status')"
          multiple
          small-chips
          deletable-chips
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersIntegrations"
          :items="availableIntegrationsFilters"
          :label="$trans('integrations')"
          multiple
          small-chips
          deletable-chips
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          :menu-props="{ offsetY: true }"
        >
          <template #item="data">
            <div class="d-flex align-center pointer my-2">
              <span class="text-break cd-v-select-line-height">{{
                data.item.text
              }}</span>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" sm="3">
        <v-select
          v-model="selectedFiltersIsPublic"
          :items="availableIsPublicFilters"
          :label="$trans('employee_is_public_filter_title')"
          outlined
          clearable
          hide-details
          :disabled="disabled"
          dense
          small-chips
          deletable-chips
          :menu-props="{ offsetY: true }"
        />
      </v-col>
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="selectedFiltersSearch"
          :label="$trans('search')"
          :placeholder="$trans('autocomplete_hint')"
          append-icon="$magnify"
          hide-details
          outlined
          single-line
          clearable
          :disabled="disabled"
          dense
        />
      </v-col>
    </v-row>
    <v-row class="my-4" justify="center">
      <v-col class="d-flex align-center justify-center" cols="12" md="3" sm="6">
        <v-btn
          color="primary"
          outlined
          small
          :disabled="disabled"
          @click="clearFilters"
        >
          {{ $trans("clear_filters") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sharedListFilters from "@/calendesk/mixins/sharedListFilters";

export default {
  name: "EmployeeFilter",
  mixins: [sharedListFilters],
  data() {
    return {
      cacheId: "employee-list-filters",
      selectedFiltersStatus: [],
      selectedFiltersIntegrations: [],
      selectedFiltersSearch: null,
      selectedFiltersIsPublic: null,
      availableIsPublicFilters: [
        {
          text: this.$trans("employee_is_public_filter_visible"),
          value: "visible",
        },
        {
          text: this.$trans("employee_is_public_filter_hidden"),
          value: "hidden",
        },
      ],
      availableIntegrationsFilters: [
        {
          text: this.$trans("service_location_3"),
          value: "google",
        },
        {
          text: this.$trans("service_location_5"),
          value: "zoom",
        },
        {
          text: this.$trans("service_location_6"),
          value: "teams",
        },
        {
          text: this.$trans("service_location_7"),
          value: "skype",
        },
        {
          text: this.$trans("microsoft_outlook"),
          value: "outlook",
        },
        {
          text: this.$trans("service_location_8"),
          value: "whatsapp",
        },
        {
          text: this.$trans("mobile_app_title"),
          value: "app",
        },
      ],
    };
  },
  computed: {
    filters() {
      return {
        selectedFiltersStatus: this.selectedFiltersStatus,
        selectedFiltersSearch: this.selectedFiltersSearch,
        selectedFiltersIntegrations: this.selectedFiltersIntegrations,
        selectedFiltersIsPublic: this.selectedFiltersIsPublic,
      };
    },
  },
  methods: {
    getData() {
      const data = {};

      if (this.selectedFiltersStatus && this.selectedFiltersStatus.length > 0) {
        data.status_ids = this.selectedFiltersStatus.join(",");
      }

      if (this.selectedFiltersSearch) {
        data.query = this.selectedFiltersSearch;
      }

      if (this.selectedFiltersIsPublic) {
        data.is_public = this.selectedFiltersIsPublic === "visible" ? 1 : 0;
      }

      if (
        this.selectedFiltersIntegrations &&
        this.selectedFiltersIntegrations.length > 0
      ) {
        data.integration_ids = this.selectedFiltersIntegrations.join(",");
      }

      return data;
    },
  },
};
</script>
