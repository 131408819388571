<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__25-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("employee") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="employee-details">
          <div class="mb-2">
            <employee-card-details
              details-in-tooltip
              justify-left
              clickable
              push-transition
              :employee="item"
            />
          </div>
          <div
            v-if="$helpers.userIsRoot(item.user)"
            class="d-flex align-center flex-wrap"
          >
            <v-chip x-small class="mr-2 my-1">
              {{ $trans("root_account_title") }}
            </v-chip>
          </div>
          <div class="d-flex align-center flex-wrap">
            <v-chip
              x-small
              :color="$helpers.getStatusColor(item.user.status).color"
              :class="$helpers.getStatusColor(item.user.status).class"
              class="mr-2 my-1"
            >
              {{ $trans(item.user.status + "_account") }}
            </v-chip>
            <v-chip
              v-if="item.is_public"
              x-small
              color="green-light"
              class="green--text my-1"
            >
              {{ $trans("employee_is_public_filter_visible") }}
            </v-chip>
            <v-chip v-else x-small color="red-light" class="red--text my-1">
              {{ $trans("employee_is_public_filter_hidden") }}
            </v-chip>
          </div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("email") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="item.user.email"
          class="pointer"
          @click.stop="$helpers.copyAndNotify(item.user.email)"
        >
          {{ item.user.email }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("phone") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div
          v-if="item.user && item.user.default_phone"
          class="pointer"
          @click.stop="$helpers.copyAndNotify(item.user.default_phone.e164)"
        >
          {{ item.user.default_phone.e164 }}
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__30-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("integrations") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="d-flex align-center justify-center flex-wrap">
          <v-tooltip v-if="item.google_calendar_enabled" bottom>
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/google-calendar.png`)"
                class="ma-1"
                v-on="on"
              />
            </template>
            {{ $trans("google_calendar") }}
          </v-tooltip>
          <v-tooltip v-if="item.google_calendar_enabled" bottom>
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/google-meet.png`)"
                class="ma-1"
                v-on="on"
              />
            </template>
            {{ $trans("google_meet") }}
          </v-tooltip>
          <v-tooltip v-if="item.zoom_enabled" bottom>
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/zoom-logo.jpg`)"
                class="ma-1 rounded-circle"
                v-on="on"
              />
            </template>
            {{ $trans("zoom") }}
          </v-tooltip>
          <v-tooltip v-if="item.outlook_enabled" bottom>
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/outlook-logo.png`)"
                class="ma-1"
                v-on="on"
              />
            </template>
            {{ $trans("microsoft_outlook") }}
          </v-tooltip>
          <v-tooltip v-if="item.teams_business_enabled" bottom>
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/teams-logo-small.png`)"
                class="ma-1"
                v-on="on"
              />
            </template>
            {{ $trans("microsoft_teams") }}
          </v-tooltip>
          <v-tooltip
            v-if="item.skype_business_enabled || item.skype_consumer_enabled"
            bottom
          >
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/skype-logo-small.png`)"
                class="ma-1"
                v-on="on"
              />
            </template>
            {{ $trans("microsoft_skype") }}
          </v-tooltip>
          <v-tooltip v-if="item.whatsapp_number" bottom>
            <template #activator="{ on }">
              <v-img
                max-height="20"
                max-width="20"
                :src="require(`@/assets/whatsapp-logo-small.png`)"
                class="ma-1"
                v-on="on"
              />
            </template>
            {{ $trans("whatsapp") }}
          </v-tooltip>
          <v-tooltip v-if="item.accepts_push" bottom>
            <template #activator="{ on }">
              <v-icon color="green" v-on="on"> $smartphone </v-icon>
            </template>
            {{ $trans("push_tooltip") }}
          </v-tooltip>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <employee-list-buttons :is-hovered="isHovered" :employee="item" />
      </div>
    </td>
  </tr>
</template>
<script>
import EmployeeCardDetails from "@/components/EmployeeCardDetails.vue";
import EmployeeListButtons from "@/views/dashboard/pages/Employees/components/EmployeeListButtons.vue";
import itemRowActions from "@/calendesk/mixins/itemRowActions";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  components: {
    EmployeeListButtons,
    EmployeeCardDetails,
  },
  mixins: [itemRowActions, sharedActions],
};
</script>
