import sharedListActions from "@/calendesk/mixins/sharedListActions";
import { goToEmployee } from "@/lib/calendesk-js-library/tools/helpers";
import userActions from "@/calendesk/mixins/userActions";

export default {
  mixins: [sharedListActions, userActions],
  methods: {
    sendNotificationsToEmployees(employees) {
      this.sendNotificationsToUsers(
        employees,
        this.$helpers.customerNotificationCustomerType.employee,
        this.$trans("send_employee_notification_title")
      );
    },
    fireAction(action, employee) {
      switch (action) {
        case "preview":
          goToEmployee(employee, true);
          break;
        case "edit":
          goToEmployee(employee, true);
          break;
        case "lock":
          this.handleLock([this.employee.user]);
          break;
        case "unlock":
          this.handleUnlock([this.employee.user]);
          break;
        case "reset_password":
          this.handleResetPassword(this.employee.user, true);
          break;
        case "delete":
          this.handleRemove(
            [
              {
                id: employee.user.id,
                employeeId: employee.id,
                status: employee.user.status,
              },
            ],
            "employee"
          );
          break;
        case "send_notification_to_employees":
          this.sendNotificationsToEmployees([employee]);
          break;
      }
    },
  },
};
