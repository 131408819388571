var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{key:_vm.item.id,staticClass:"table-row",class:{
    'v-data-table__mobile-table-row': !_vm.$vuetify.breakpoint.mdAndUp,
  },on:{"click":function($event){$event.stopPropagation();return _vm.clickRow(_vm.item)},"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__checkbox-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"}):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('v-simple-checkbox',{attrs:{"ripple":false,"value":_vm.isSelected,"color":"primary"},on:{"input":function($event){return _vm.selectOnChange($event)}}})],1)]),_c('td',{staticClass:"pt-2 text-center",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__5-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_c('div',[_vm._v(" "+_vm._s(_vm.$trans("id"))+" ")])]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"text-left"},[_c('div',[_vm._v(_vm._s(_vm.item.id))])])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__25-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("employee"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"employee-details"},[_c('div',{staticClass:"mb-2"},[_c('employee-card-details',{attrs:{"details-in-tooltip":"","justify-left":"","clickable":"","push-transition":"","employee":_vm.item}})],1),(_vm.$helpers.userIsRoot(_vm.item.user))?_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-chip',{staticClass:"mr-2 my-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.$trans("root_account_title"))+" ")])],1):_vm._e(),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-chip',{staticClass:"mr-2 my-1",class:_vm.$helpers.getStatusColor(_vm.item.user.status).class,attrs:{"x-small":"","color":_vm.$helpers.getStatusColor(_vm.item.user.status).color}},[_vm._v(" "+_vm._s(_vm.$trans(_vm.item.user.status + "_account"))+" ")]),(_vm.item.is_public)?_c('v-chip',{staticClass:"green--text my-1",attrs:{"x-small":"","color":"green-light"}},[_vm._v(" "+_vm._s(_vm.$trans("employee_is_public_filter_visible"))+" ")]):_c('v-chip',{staticClass:"red--text my-1",attrs:{"x-small":"","color":"red-light"}},[_vm._v(" "+_vm._s(_vm.$trans("employee_is_public_filter_hidden"))+" ")])],1)])])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("email"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.user.email)?_c('div',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$helpers.copyAndNotify(_vm.item.user.email)}}},[_vm._v(" "+_vm._s(_vm.item.user.email)+" ")]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__15-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("phone"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[(_vm.item.user && _vm.item.user.default_phone)?_c('div',{staticClass:"pointer",on:{"click":function($event){$event.stopPropagation();return _vm.$helpers.copyAndNotify(_vm.item.user.default_phone.e164)}}},[_vm._v(" "+_vm._s(_vm.item.user.default_phone.e164)+" ")]):_vm._e()])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__30-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-data-table__mobile-row__header"},[_vm._v(" "+_vm._s(_vm.$trans("integrations"))+" ")]):_vm._e(),_c('div',{class:{
        'v-data-table__mobile-row__cell': !_vm.$vuetify.breakpoint.mdAndUp,
      }},[_c('div',{staticClass:"d-flex align-center justify-center flex-wrap"},[(_vm.item.google_calendar_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/google-calendar.png")}},on))]}}],null,false,2649067815)},[_vm._v(" "+_vm._s(_vm.$trans("google_calendar"))+" ")]):_vm._e(),(_vm.item.google_calendar_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/google-meet.png")}},on))]}}],null,false,4048312268)},[_vm._v(" "+_vm._s(_vm.$trans("google_meet"))+" ")]):_vm._e(),(_vm.item.zoom_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1 rounded-circle",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/zoom-logo.jpg")}},on))]}}],null,false,758728120)},[_vm._v(" "+_vm._s(_vm.$trans("zoom"))+" ")]):_vm._e(),(_vm.item.outlook_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/outlook-logo.png")}},on))]}}],null,false,3890027678)},[_vm._v(" "+_vm._s(_vm.$trans("microsoft_outlook"))+" ")]):_vm._e(),(_vm.item.teams_business_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/teams-logo-small.png")}},on))]}}],null,false,2902338347)},[_vm._v(" "+_vm._s(_vm.$trans("microsoft_teams"))+" ")]):_vm._e(),(_vm.item.skype_business_enabled || _vm.item.skype_consumer_enabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/skype-logo-small.png")}},on))]}}],null,false,586122513)},[_vm._v(" "+_vm._s(_vm.$trans("microsoft_skype"))+" ")]):_vm._e(),(_vm.item.whatsapp_number)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-img',_vm._g({staticClass:"ma-1",attrs:{"max-height":"20","max-width":"20","src":require("@/assets/whatsapp-logo-small.png")}},on))]}}],null,false,2755365021)},[_vm._v(" "+_vm._s(_vm.$trans("whatsapp"))+" ")]):_vm._e(),(_vm.item.accepts_push)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v(" $smartphone ")])]}}],null,false,1447896431)},[_vm._v(" "+_vm._s(_vm.$trans("push_tooltip"))+" ")]):_vm._e()],1)])]),_c('td',{staticClass:"py-2",class:{
      'v-data-table__mobile-row': !_vm.$vuetify.breakpoint.mdAndUp,
      'v-data-table__manage-row': _vm.$vuetify.breakpoint.mdAndUp,
    }},[_c('div',{staticClass:"row-list-buttons white rounded mr-1 mt-2"},[_c('employee-list-buttons',{attrs:{"is-hovered":_vm.isHovered,"employee":_vm.item}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }