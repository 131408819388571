<template>
  <list-buttons
    :is-hovered="isHovered"
    :extra-actions="extraActions"
    :primary-actions="primaryActions"
    @fireAction="fire"
  />
</template>
<script>
import ListButtons from "@/components/ListButtons.vue";
import employeeActions from "@/calendesk/mixins/employeeActions";
import { userHasRole } from "@/lib/calendesk-js-library/tools/helpers";

export default {
  name: "EmployeeListButtons",
  components: { ListButtons },
  mixins: [employeeActions],
  props: {
    employee: {
      type: Object,
      required: true,
    },
    isHovered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    extraActions() {
      const actions = [];

      actions.push({
        action: "edit",
        color: "light",
        icon: "$pencil",
        title: this.$trans("edit"),
      });

      if (this.canSendNotificationsToEmployee(this.employee)) {
        actions.push({
          action: "send_notification_to_employees",
          color: "light",
          icon: "$send",
          title: this.$trans("send_employee_notification_title"),
        });
      }

      actions.push({
        action: "reset_password",
        color: "light",
        icon: "$password",
        title: this.$trans("reset_password"),
      });

      if (
        !this.$helpers.userHasRole(
          this.employee.user,
          this.$helpers.roleSlugs.root
        )
      ) {
        if (this.employee.user.status === "active") {
          actions.push({
            action: "lock",
            color: "error",
            icon: "$lock",
            title: this.$trans("lock_account"),
          });
        } else {
          actions.push({
            action: "unlock",
            color: "light",
            icon: "$unlock",
            title: this.$trans("unblock_account"),
          });
        }

        actions.push({
          action: "delete",
          color: "error",
          icon: "$trash-default",
          title: this.$trans("delete"),
        });
      }

      return actions;
    },
    primaryActions() {
      const actions = [
        {
          action: "edit",
          color: "light",
          icon: "$pencil",
          title: this.$trans("edit"),
        },
      ];

      if (this.canSendNotificationsToEmployee(this.employee)) {
        actions.push({
          action: "send_notification_to_employees",
          color: "light",
          icon: "$send",
          title: this.$trans("send_employee_notification_title"),
        });
      }

      return actions;
    },
  },
  methods: {
    fire(action) {
      this.fireAction(action, this.$helpers.cloneObject(this.employee));
    },
  },
};
</script>
