<template>
  <v-card :rounded="!flat" :elevation="flat ? 0 : 3">
    <v-card-text class="mx-0 mb-8 px-0 py-8">
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
